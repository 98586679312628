.twenty-width {
  max-width: 550px;
}
.home-slider-image {
  width: "100%";
  height: "1080px";
}
.home-slider .bg-home {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.techspec-table table th:nth-child(n + 2) {
  padding-right: 10px;
  text-align: center;
}

.techspec-table table td:nth-child(n + 3) {
  text-align: center;
}

.product-detail-page .big-image{
  width: 420px !important;
  height: 300px !important;
  object-fit: scale-down;
}
.product-detail-page .thumbnail-image{
  width: 120px !important;
  height: 80px !important;
}

.product-detail-page .sizes-text {
  padding: 6px;
  font-weight: 600;
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%);
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 6px;
}

.product-detail-page .techspec-table{
  width: 75% !important;
}

.product-detail-page .size-info-table{
  width: 50% !important;
}
.aboutus-page .top-image-small{
  margin-top: -100px;
  margin-left: -100px;
  
}
.aboutus-text{
  text-align: justify;
}

.aboutus-page .top-image-small img{
 width: 500px !important;
}
.highlightsImg{
  object-fit: cover;
}

.page-not-found-img{
  width: 475px;
}

@media screen and (max-width: 1024px){
  .aboutus-page .top-image-small{
    margin-top: -100px;
    margin-left: -150px;
  }
  .aboutus-page .top-image-small img{
    width: 350px !important;
   }
}
@media screen and (max-width: 768px){
  .product-detail-page .techspec-table{
    width: 100% !important;
  }

  .aboutus-page .top-image-small{
    margin-top: 0;
    margin-left: 0;
  }
  .aboutus-page .top-image-small img{
    width: auto;
   }
}

@media screen and (max-width: 480px) {
  .sliderImg {
    height: 600px;
    width: 480px;
  }
  .sliderText {
    max-width: 68%;
    font-size: small;
    top: 0;
  }
  .home-slider-image {
    width: "100%";
    height: "100%";
  }
}

@media screen and (max-width: 414px) {
  .home-slider {
    padding-top: 100px;
  }

  .product-detail-page .size-info-table{
    width: 100% !important;
  }

  .page-not-found-img{
    width: 300px;
  }
}

/* --- Navbar / Topnav --- */
body h1,
h2,
h3,
h4,
h5 {
  /* font-family: 'Ubuntu', sans-serif ; */
  color: #57b1ba;
  font-weight: 700;
}
.nav-item {
  padding: 0 15px;
}

.nav-item .nav-link {
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  color: #3c4858;
}

.dropdown-menu .dropdown-item {
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #3c4858;
}

.nav-item .dropdown-menu {
  border: none !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 4px 0px;
}

.nav-item .nav-link:hover {
  color: #0d6efd !important;
}

.active {
  color: #0d6efd !important;
}

.dropdown-toggle::after {
  display: none;
}

.categ {
  display: flex;
}
.categ > div {
  flex: 0 0 50%; /* width of first flex item in main container */
  height: 800px;
}
.sub {
  flex: 0 0 50%; /* width of second flex item in main container */
  display: flex; /* nested flex container to arrange smaller items */
  flex-wrap: wrap; /* make container multi-line */
  order: -1;
}
.sub > .item {
  flex: 0 0 50%; /* width of smaller items; two max per row */
  height: 400px;
}
.item {
  background-color: #ccc;
  overflow: hidden;
  /* padding: 5px;
    margin: 5px; */
}
.item img {
  width: 100%;
  height: auto;
}

.smal-img {
  height: auto;
  width: auto;
  max-width: 200px;
  max-height: 200px;
}
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.scrolled {
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.8s;
}

.top-nav .container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* --- Footer --- */

.foot-subscribe #emailsubscribe {
  background-color: #3c4858 !important;
  border: 1px solid #8492a6 !important;
  color: #f8f9fc !important;
}

.search-form .form-group {
  float: right !important;
  transition: all 0.35s, border-radius 0s;
  width: 32px;
  height: 32px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-radius: 25px;
  border: 1px solid #ccc;
}
.search-form .form-group input.form-control {
  padding-right: 20px;
  border: 0 none;
  background: transparent;
  box-shadow: none;
  display: block;
}
.search-form .form-group input.form-control::-webkit-input-placeholder {
  display: none;
}
.search-form .form-group input.form-control:-moz-placeholder {
  /* Firefox 18- */
  display: none;
}
.search-form .form-group input.form-control::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
}
.search-form .form-group input.form-control:-ms-input-placeholder {
  display: none;
}
.search-form .form-group:hover,
.search-form .form-group.hover {
  width: 100%;
  border-radius: 4px 25px 25px 4px;
}
.search-form .form-group span.form-control-feedback {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #3596e0;
  left: initial;
  font-size: 14px;
}

.accordion,
.accordion-item,
.accordion-header {
  background-color: #fbf8f3;
}
.accordion-button:not(.collapsed) {
  background-color: #fbf8f3;
  color: #555555;
}

.accordion .accordion-item .accordion-button.collapsed {
  background-color: #fbf8f3;
}

/*
  The grid itself needs only 4 CSS declarations:
*/

#categories img {
  width: 100%;
  height: auto;
  transition: all 0.35s ease;
}

#categories .no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
#categories .no-gutter > [class*="col-"] figcaption {
  position: absolute;
  margin: 0;
  padding: 0;
  top: -100%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 3px 3px;
  transition: all 0.35s ease-in-out;
}
#categories .no-gutter > [class*="col-"]:hover figcaption {
  left: 0;
  top: 50%;
  left: 50%;
  transition: all 0.35s ease;
}
#categories .no-gutter > [class*="col-"]:hover figcaption h1 {
  padding: 0;
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#categories .no-gutter > [class*="col-"]:hover figcaption hr {
  height: 2px;
  border: none;
  background-color: #fff;
  position: relative;
  top: 42%;
  margin: 0 auto;
  padding: 0;
  width: 50%;
}
#categories .no-gutter > [class*="col-"]:hover img {
  transform: scale(1.2);
}

#categories .hello {
  width: 100%;
  margin: 10px auto;
  padding: 0;
}

/* .dropdownlist:hover > .level-1{ 
  opacity: 1 !important;
  top: 100%;
  visibility: visible !important;
  margin-top: -28px;
  list-style: none;
}
.level-1{
  margin-left: 150px;
  margin-top: -10px;
  opacity: 0 !important;
  visibility: hidden !important;
}

.level-0{
  opacity: 0 !important;
} */
.products-dropdown:hover > .level-0 {
opacity: 1 !important;
visibility: visible;

}

.table th{
  font-weight: bold;
}
.table td{
  padding: 2px 10px;
  margin: 1px 2px;
}

.description >table {
  margin-left: 15px;
  border-collapse: collapse
}
.description >.table td{
  border: none;
}
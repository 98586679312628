.masonary-banner .blog:hover .overlay{
    transition: all 0.3s linear;
}
.masonary-banner .row > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.masonary-banner .col:hover .overlay-work{
    padding-left: 50px !important;
    padding-right: 50px !important;
}

@media screen and (min-width: 991px) {   
    .masonary-banner .work-container.work-modern .r-0{
        right: 10px;
    }
}

.masonary-banner .p-0 img{
    height: 400px !important;
    width: 100%;
}

.masonary-banner .large-image img{
    height: 800px !important;
}

.masonary-banner .large-image img:hover{
    padding-left: 10px;
}
